export enum API_TYPES {
  BENEFITS_ROADMAP_API = 'BENEFITS_ROADMAP_API',
}

const developmentApi: Record<API_TYPES, string> = {
  BENEFITS_ROADMAP_API: 'https://benefits-roadmap-api-ch-hulk.os-dev.io/api/',
}

const productionApi: Record<API_TYPES, string> = {
  BENEFITS_ROADMAP_API: 'https://benefits-roadmap-api-ch-stage.os-dev.io/api/',
}

const config = Object.keys(API_TYPES).reduce((acc, type) => {
  const api =
    process.env.NODE_ENV !== 'development'
      ? process.env[`REACT_APP_${type}`] || productionApi[type as API_TYPES]
      : developmentApi[type as API_TYPES]
  return { ...acc, [type]: api }
}, {} as Record<API_TYPES, string>)

export default config
